import logo from './logo.svg';
import './App.css';
import {RouterProvider} from "react-router-dom";
import router from "./router";
import {AuthProvider} from "./contexts/AuthContext";
import {MessagesProvider} from "./contexts/MessagesContext";
import React from "react";
import * as Sentry from "@sentry/react";
import useVersionChecker from './hooks/useVersionChecker';

console.log("Sentry DSN:", process.env.REACT_APP_SENTRY_DSN);

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/business.unatam\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


function App() {

    useVersionChecker();

    return (
      <Sentry.ErrorBoundary fallback={<p>Something went wrong...</p>}>
      <AuthProvider>

          <MessagesProvider>
          <RouterProvider router={router} />
          </MessagesProvider>

      </AuthProvider>
      </Sentry.ErrorBoundary>

  );
}

export default App;

