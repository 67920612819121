import { useEffect } from 'react';

export default function useVersionChecker(intervalMinutes = 60) {
    useEffect(() => {
        let currentVersion = null;

        const checkVersion = async () => {
            try {
                const res = await fetch('/version.json?_=' + Date.now());
                const data = await res.json();
                if (!currentVersion) {
                    currentVersion = data.version;
                } else if (currentVersion !== data.version) {
                    console.log('New version detected, reloading...');
                    window.location.reload(true); // Обновляем с очисткой кэша
                }
            } catch (err) {
                console.error('Version check failed', err);
            }
        };

        // Проверка при фокусе окна
        const onFocus = () => checkVersion();
        window.addEventListener('focus', onFocus);

        // Интервальная проверка
        const interval = setInterval(() => {
            checkVersion();
        }, intervalMinutes * 60 * 1000);

        // Первая проверка при монтировании
        checkVersion();

        return () => {
            clearInterval(interval);
            window.removeEventListener('focus', onFocus);
        };
    }, [intervalMinutes]);
}